import React from 'react'

const BackgroundLines = props => (
  <div className="mi-bglines">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
)

export default BackgroundLines
